<template>
    <div class="reset-password-container">
      <el-card class="reset-password-card">
        <h2>Reset Password</h2>
        <el-form label-position="top" :model="resetForm">
          <el-form-item label="Username">
            <el-input v-model="resetForm.username">
              <template #prefix>
                <el-icon class="input-icon"><User /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="resetPassword">Reset Password</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </template>
  
  <script>
  import axios from '@/axios'
  
  export default {
    data() {
      return {
        resetForm: {
          username: ''
        }
      }
    },
    methods: {
      resetPassword() {
        axios.post('/api/reset-password/', this.resetForm)
        .then(() => {
          this.$message.success('Password reset link sent')
          this.$router.push('/login')
        })
        .catch(error => {
          console.log(error)
          this.$message.error('Failed to send password reset link')
        })
      }
    }
  }
  </script>
  
  <style scoped>
  .reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .reset-password-card {
    width: 400px;
    padding: 20px;
    text-align: left;
  }
  
  .el-input__inner {
    padding-left: 30px; /* 调整图标和文本之间的间距 */
  }
  
  .input-icon {
    padding-right: 10px;
    border-right: 1px solid #dcdfe6; /* 添加分割线 */
    margin-right: 10px;
  }
  </style>
  