import { createStore } from 'vuex';
import auth from './modules/auth';
import user from './modules/user';
import register from './modules/register'
import promotions from './modules/promotions';
import bonusReport from './modules/bonusReport';
import redPacket from './modules/redPacket';

const store = createStore({
  modules: {
    auth,
    user,
    register,
    promotions,
    bonusReport,
    redPacket
  }
});

export default store;
