<template>
  <el-header :class="['navbar', { 'navbar-mobile': isMobile, 'navbar-collapsed': isSidebarCollapsed }]">
    <div class="navbar-content">
      <div class="left-section">
        <el-button 
          v-if="isMobile" 
          @click="$emit('toggle-sidebar')" 
          icon="Grid" 
          class="sidebar-toggle-btn"
        ></el-button>
        <el-breadcrumb>
          <el-breadcrumb-item v-for="item in breadcrumb" :key="item">
            {{ item }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="right-section">
        <el-tag type="info" class="time-tag">{{ currentTime }} {{ timeZoneOffset }}</el-tag>
        <el-dropdown @command="handleCommand">
          <template #default>
            <span class="el-dropdown-link">
              你好, {{ userName }}
              <el-icon><Avatar /></el-icon>
            </span>
          </template>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="account">我的账户</el-dropdown-item>
              <el-dropdown-item command="logout">登出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </el-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isSidebarCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentTime: '',
      timeZoneOffset: ''
    };
  },
  computed: {
    ...mapGetters('user', ['userName']),
    breadcrumb() {
      return this.$route.path.split('/').filter(Boolean).map(path => {
        return path.charAt(0).toUpperCase() + path.slice(1);
      });
    }
  },
  created() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
    this.initializeUser();
  },
  methods: {
    ...mapActions('user', ['initializeUser']),
    ...mapActions('auth', ['logout']),
    updateTime() {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString('en-GB', { hour12: false });
      this.timeZoneOffset = this.formatTimeZoneOffset(now.getTimezoneOffset());
    },
    formatTimeZoneOffset(minutes) {
      const sign = minutes <= 0 ? '+' : '-';
      const hours = Math.floor(Math.abs(minutes) / 60);
      const mins = Math.abs(minutes) % 60;
      return `GMT${sign}${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.logout().then(() => {
          this.$router.push('/login');
        }).catch(error => {
          console.error('Logout failed:', error);
        });
      } else if (command === 'account') {
        this.goToProfile();
      }
    },
    goToProfile() {
      const uid = localStorage.getItem('uid');
      this.$router.push({ name: 'Profile', query: { uid } });
    }
  }
};
</script>

<style scoped>
.navbar {
  background-color: #ffffff;
  color: #7e838d;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: margin-left 0.3s, width 0.3s;
}

.navbar:not(.navbar-mobile):not(.navbar-collapsed) {
  width: calc(100% - 250px);
}

.navbar-collapsed {
  width: calc(100% - 64px);
}

.navbar-mobile {
  width: 100%;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-section {
  display: flex;
  align-items: center;
}

.right-section {
  display: flex;
  align-items: center;
}

.sidebar-toggle-btn {
  background-color: transparent;
  color: #7e838d;
  border: none;
  cursor: pointer;
}

.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.el-breadcrumb-item {
  color: #7e838d;
}

.time-tag {
  background-color: #cafff2;
  border: none;
  font-weight: 700;
  color: #000000;
  border-radius: 5px;
  padding: 5px 10px;
}
</style>
