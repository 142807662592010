import axios from '@/axios'

const state = {
  registrationStatus: '',
  registrationError: '',
  referralCode: '',
  isReferralLocked: false
}

const mutations = {
  setRegistrationStatus(state, status) {
    state.registrationStatus = status
  },
  setRegistrationError(state, error) {
    state.registrationError = error
  },
  setReferralCode(state, code) {
    state.referralCode = code
  },
  setIsReferralLocked(state, isLocked) {
    state.isReferralLocked = isLocked
  }
}

const actions = {
  registerUser({ commit }, userData) {
    return axios.post('/api/register/', userData)
      .then(() => {
        commit('setRegistrationStatus', 'success')
        commit('setRegistrationError', '')
      })
      .catch(error => {
        commit('setRegistrationStatus', 'error')
        if (error.response && error.response.data) {
          commit('setRegistrationError', error.response.data.detail || 'Please try again.')
        } else {
          commit('setRegistrationError', 'Registration failed. Please try again.')
        }
        throw error
      })
  },
  initializeReferralCode({ commit }) {
    const urlParams = new URLSearchParams(window.location.search)
    const referralCode = urlParams.get('referral_code')
    if (referralCode) {
      commit('setReferralCode', referralCode)
      commit('setIsReferralLocked', true)
    }
  }
}

const getters = {
  registrationStatus(state) {
    return state.registrationStatus
  },
  registrationError(state) {
    return state.registrationError
  },
  referralCode(state) {
    return state.referralCode
  },
  isReferralLocked(state) {
    return state.isReferralLocked
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
