<template>
  <div class="red-packet-form-container">
    <el-card>
      <h2>{{ isEdit ? '编辑红包活动' : '创建红包活动' }}</h2>
      <el-form :model="form" label-width="120px">
        <el-form-item label="活动名称">
          <el-input v-model="form.name" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="总金额">
          <el-input v-model="form.total_amount" type="number" placeholder="请输入总金额"></el-input>
        </el-form-item>
        <el-form-item label="最小金额">
          <el-input v-model="form.min_amount" type="number" placeholder="请输入最小金额"></el-input>
        </el-form-item>
        <el-form-item label="最大金额">
          <el-input v-model="form.max_amount" type="number" placeholder="请输入最大金额"></el-input>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="form.start_time" type="datetime" placeholder="选择开始时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="form.end_time" type="datetime" placeholder="选择结束时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="是否有效">
          <el-switch v-model="form.is_active"></el-switch>
        </el-form-item>
        <el-form-item label="领取条件">
          <el-select v-model="form.claim_condition" placeholder="选择领取条件">
            <el-option label="每日" value="daily"></el-option>
            <el-option label="每周" value="weekly"></el-option>
            <el-option label="每月" value="monthly"></el-option>
            <el-option label="每年" value="yearly"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.claim_condition" label="最大领取次数">
          <el-input v-model="form.max_claims" type="number" placeholder="请输入最大领取次数"></el-input>
        </el-form-item>
        <el-form-item label="赔率">
          <el-input v-model="form.odds" type="number" placeholder="请输入赔率"></el-input>
        </el-form-item>
        <el-form-item label="每日份额">
          <el-input v-model="form.daily_quota" type="number" placeholder="请输入每日份额"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" icon="CircleCheckFilled" @click="submitForm">{{ isEdit ? '更新' : '创建' }}</el-button>
          <el-button type="warning" icon="Refresh" @click="resetForm">重置</el-button>
          <el-button type="danger" icon="CircleCloseFilled" @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      form: {
        id: null,
        name: '',
        total_amount: 0,
        min_amount: 0,
        max_amount: 0,
        start_time: '',
        end_time: '',
        is_active: true,
        claim_condition: '', 
        max_claims: 0,       
        odds: 1.0, // 新增字段：赔率
        daily_quota: 0, // 新增字段：每日份额
      },
      isEdit: false,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.loadRedPacket(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions('redPacket', ['createRedPacket', 'updateRedPacket', 'fetchRedPacketById']),
    loadRedPacket(id) {
      this.fetchRedPacketById(id).then(redPacket => {
        this.form = { ...redPacket };
      });
    },
    submitForm() {
      if (this.isEdit) {
        this.updateRedPacket(this.form)
          .then(() => {
            this.$message.success('红包活动更新成功');
            this.$router.go(-1);
          })
          .catch(() => {
            this.$message.error('红包活动更新失败');
          });
      } else {
        this.createRedPacket(this.form)
          .then(() => {
            this.$message.success('红包活动创建成功');
            this.$router.go(-1);
          })
          .catch(() => {
            this.$message.error('红包活动创建失败');
          });
      }
    },
    resetForm() {
      this.form = {
        id: null,
        name: '',
        total_amount: 0,
        min_amount: 0,
        max_amount: 0,
        start_time: '',
        end_time: '',
        is_active: true,
        claim_condition: '', 
        max_claims: 0,       
        odds: 1.0, // 重置赔率
        daily_quota: 0, // 重置每日份额
      };
      this.isEdit = false;
    },
    cancel() {
      this.$router.go(-1);
    }
  }
};
</script>
