<template>
  <el-aside :class="['sidebar', { 'sidebar-mobile': isMobile, 'sidebar-open': isSidebarOpen, 'sidebar-collapsed': isSidebarCollapsed }]">
    <div class="sidebar-header">
      <img src="@/assets/logo.png" alt="Logo" class="logo" v-if="!isSidebarCollapsed"/>
      <el-button @click="toggleCollapse" :icon="isSidebarCollapsed ? 'Expand' : 'Fold'" class="collapse-btn"></el-button>
    </div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      :collapse="isSidebarCollapsed"
      @select="handleSelect"
      :unique-opened="true"
    >
      <el-menu-item index="/dashboard">
        <template v-slot:title>
          <el-icon><HomeFilled /></el-icon>
          <span v-if="!isSidebarCollapsed">Dashboard</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/users/lists">
        <template v-slot:title>
          <el-icon><User /></el-icon>
          <span v-if="!isSidebarCollapsed">User List</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/promotions">
        <template v-slot:title>
          <el-icon><Present /></el-icon>
          <span v-if="!isSidebarCollapsed">Promotion List</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/promotions/given">
        <template v-slot:title>
          <el-icon><Money /></el-icon>
          <span v-if="!isSidebarCollapsed">Give Promotion</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/setting/redpacket-settings">
        <template v-slot:title>
          <el-icon><Coin /></el-icon>
          <span v-if="!isSidebarCollapsed">Red Packets</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/banner-setting">
        <template v-slot:title>
          <el-icon><Coin /></el-icon>
          <span v-if="!isSidebarCollapsed">setting</span>
        </template>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isSidebarOpen: {
      type: Boolean,
      default: false
    },
    isSidebarCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: ''
    };
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path;
    }
  },
  created() {
    this.activeIndex = this.$route.path;
  },
  methods: {
    handleSelect(key) {
      this.$router.push(key);
    },
    toggleCollapse() {
      this.$emit('toggle-sidebar');
    }
  }
};
</script>

<style scoped>
.sidebar {
  background-color: #1e1e2d;
  color: #fff;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  transition: transform 0.3s, width 0.3s;
  z-index: 2000;  /* Ensure sidebar is above other content */
}

.sidebar-mobile {
  transform: translateX(-100%);
}

.sidebar-open {
  transform: translateX(0);
}

.sidebar-collapsed {
  width: 64px;  /* Width for collapsed sidebar */
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.logo {
  height: 40px;
}

.collapse-btn {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
}

.el-menu {
  border-right: none;
}

.el-menu-item,
.el-submenu-title {
  color: #a0a0a0;
  background-color: #1e1e2d;
  font-size: 12px;
}

.el-menu-item .el-icon,
.el-submenu .el-icon {
  color: #a0a0a0; /* 默认图标颜色 */
}

.el-menu-item:hover .el-icon,
.el-menu-item:focus .el-icon,
.el-submenu:hover .el-icon,
.el-submenu:focus .el-icon {
  color: #286cff; /* 悬停和聚焦状态的图标颜色 */
}

.el-menu-item.is-active .el-icon,
.el-submenu.is-active .el-icon {
  color: #286cff; /* 活跃状态的图标颜色 */
}

.el-menu-item:focus,
.el-menu-item:hover,
.el-submenu-title:focus,
.el-submenu-title:hover {
  background-color: #18181f;
  color: #fff;
  font-size: 12px;
}

.el-menu-item.is-active,
.el-submenu-title.is-active {
  background-color: #18181f;
  color: #fff;
  font-size: 12px;
}
</style>
