<template>
  <div class="red-packet-page">
    <div class="header">
      <img src="@/assets/header-bg.png" alt="Grab the Fortune, Win Luck" class="header-image" />
    </div>

    <div class="red-packet-container">
      <div class="countdown">
        <h2>挑战你的运气</h2>
        <div class="timer-container">
          <p class="timer">{{ hours }} : {{ minutes }} : {{ seconds }}</p>
          <div class="time-labels">
            <span>小时</span>
            <span>分钟</span>
            <span>秒</span>
          </div>
        </div>
      </div>
      <div class="buttons">
        <el-button class="grab-button" @click="handleRedPacketClick">抢红包 ➔</el-button>
        <div class="remaining-grabs">
          <el-tag type="warning">{{ remainingGrabs }} Grab(s) left</el-tag>
        </div>
      </div>
    </div>
    <div class="terms-container">
      <h2 class="terms-title">条款和条件</h2>
      <div class="terms-content">
        <p>充值福利 青包奖金大派送</p>
        <p>会员可以任意点击屏幕上飘落的青包来兑换现金奖励。</p>
        <p>在活动期间，会员每天可以有最多3次获得青包奖励的机会。</p>
        <p>活动期间: 从2024年7月15日至2024年9月30日</p>
        <p>活动详情:</p>
        <ul>
          <li>每累计存款MYR 100，会员将获得一次兑换一个(1)青包奖励的机会。</li>
          <li>奖励计算截止时间为每天23:00(GMT+8)。</li>
          <li>所有充值时间计算在奖励计算截止时间完成充值，才能获得青包的资格。</li>
          <li>会员在截止时间后完成充值或兑换的金额，将计入下一个活动的奖励。</li>
          <li>会员每天只能兑换的青包奖励兑换的活动时间是00:00至23:00(GMT+8)时间。</li>
          <li>如果会员当天没有参与兑换的资格，将不会计入奖励。</li>
          <li>所有奖励将自动计入会员的账户中，无需手动申请。</li>
          <li>会员只可使用其注册信息参加此活动，不得转让或出售。</li>
        </ul>
        <p>条款和条件</p>
        <p>此活动仅适用于充值并正确填写注册信息的会员。</p>
        <p>参与会员必须接受并遵守上述所有条款和条件，如有违规将取消参与资格。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
      intervalId: null,
      showAnimation: true,
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters('redPacket', ['redPacket', 'remainingGrabs']),
  },
  created() {
    this.checkRedPacketAvailability();
    this.fetchRemainingGrabs();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions('redPacket', ['fetchRedPacket', 'grabRedPacket', 'fetchRemainingGrabs']),
    startCountdown() {
      this.intervalId = setInterval(() => {
        const redPacket = this.redPacket;
        if (!redPacket) return;

        const endTime = new Date(redPacket.end_time).getTime();
        const currentTime = new Date().getTime();
        const distance = endTime - currentTime;

        if (distance < 0) {
          clearInterval(this.intervalId);
          this.hours = '00';
          this.minutes = '00';
          this.seconds = '00';
        } else {
          this.hours = String(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
          this.minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
          this.seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, '0');
        }
      }, 1000);
    },
    checkRedPacketAvailability() {
      this.fetchRedPacket().then(response => {
        if (response) {
          this.startCountdown();
        }
      }).catch(error => {
        console.error('Error fetching red packet:', error);
      });
    },
    handleRedPacketClick() {
      this.grabRedPacket().then(response => {
        if (response.status === 200) {
          this.$message.success(response.data.message);
          this.fetchRemainingGrabs(); // Update remaining grabs after successful grab
        } else {
          this.$message.error(response.data.message);
        }
      }).catch(error => {
        this.$message.error(error.response.data.message || 'Failed to grab red packet');
        console.error(error);
      });
    },
    showTerms() {
      this.dialogVisible = true;
    },
    getPacketStyle() {
      const delay = Math.random() * 2;
      const left = Math.random() * 100;
      return {
        animationDelay: `${delay}s`,
        left: `${left}%`
      };
    }
  },
  watch: {
    redPacket(newVal) {
      if (newVal) {
        this.startCountdown();
      }
    }
  }
};
</script>

<style scoped>
.red-packet-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #ff0000 0%, #a80000 100%);
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 20px 10px 50px 10px; /* 添加内边距以确保边距 */
}

.header-image {
  max-width: 80%;
}

.countdown {
  background: rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid #dddddd; /* 添加边框线条颜色 */
  box-sizing: border-box; /* 确保边框包含在宽度内 */
}

.countdown h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #ffffff;
}

.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer {
  font-weight: 700;
  font-size: 2.5em;
  margin-bottom: 5px;
}

.time-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.time-labels span {
  font-size: 0.9em;
  color: #ffffff;
  
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.grab-button {
  background-color: #ff4500;
  color: #fff;
  border: none;
  font-size: 1.2em;
  padding: 10px 20px;
}

.remaining-grabs {
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.2em;
  color: #ffffff;
}

.terms-container {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 900px;
  text-align: left;
  margin-top: 20px;
  border: 1px solid #ffd700; /* 添加边框线条颜色 */
  box-sizing: border-box; /* 确保边框包含在宽度内 */
}

.terms-title {
  font-size: 2em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.terms-content {
  color: #ffffff;
}

.terms-content ul {
  list-style: disc;
  padding-left: 20px;
}

@keyframes fall {
  0% {
    top: -10%;
    opacity: 1;
  }
  100% {
    top: 110%;
    opacity: 0;
  }
}

.el-dialog {
  color: black;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .header h1 {
    font-size: 2em;
  }

  .countdown h2 {
    font-size: 1.5em;
  }

  .timer {
    font-size: 2em;
  }

  .grab-button, .terms-button {
    font-size: 1em;
    padding: 8px 16px;
  }

  .remaining-grabs {
    font-size: 1em;
  }

  .terms-title {
    font-size: 1.5em;
  }

  .terms-content p, .terms-content ul {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.5em;
  }

  .countdown h2 {
    font-size: 1.2em;
  }

  .timer {
    font-size: 1.5em;
  }

  .grab-button, .terms-button {
    font-size: 0.9em;
    padding: 6px 12px;
  }

  .remaining-grabs {
    font-size: 0.9em;
  }

  .terms-title {
    font-size: 1.2em;
  }

  .terms-content p, .terms-content ul {
    font-size: 0.8em;
  }
}
</style>
