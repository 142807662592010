// store/modules/auth.js
import axios from '@/axios';
import router from '@/router';
import { ElNotification } from 'element-plus';

const state = {
  token: localStorage.getItem('token') || '',
  refreshToken: localStorage.getItem('refresh_token') || '',
  uid: localStorage.getItem('uid') || '',
  tokenExpirationDate: localStorage.getItem('token_expiration_date') || null,
  isTokenRefreshing: false,
  hasShownExpiryAlert: false,
  isLoggingIn: false // 添加登录状态
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    localStorage.setItem('token', token);
  },
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
    localStorage.setItem('refresh_token', refreshToken);
  },
  setUid(state, uid) {
    state.uid = uid;
    localStorage.setItem('uid', uid);
  },
  setTokenExpirationDate(state, expirationDate) {
    state.tokenExpirationDate = expirationDate;
    localStorage.setItem('token_expiration_date', expirationDate);
  },
  clearAuthData(state) {
    state.token = '';
    state.refreshToken = '';
    state.uid = '';
    state.tokenExpirationDate = null;
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('uid');
    localStorage.removeItem('user');
    localStorage.removeItem('token_expiration_date');
  },
  setTokenRefreshing(state, status) {
    state.isTokenRefreshing = status;
  },
  setHasShownExpiryAlert(state, status) {
    state.hasShownExpiryAlert = status;
  },
  setLoggingIn(state, status) {
    state.isLoggingIn = status; // 设置登录状态
  }
};

const actions = {
  login({ commit }, authData) {
    commit('setLoggingIn', true); // 开始登录
    return axios.post('/api/login/', authData)
      .then(response => {
        const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
        commit('setToken', response.data.access);
        commit('setRefreshToken', response.data.refresh);
        commit('setUid', response.data.uid);
        commit('setTokenExpirationDate', expirationDate);
        commit('setHasShownExpiryAlert', false);
        commit('setLoggingIn', false); // 登录成功
        ElNotification.success({
          title: 'Success',
          message: 'Login successful!',
        });
        router.push('/dashboard');
        return response;
      })
      .catch(error => {
        commit('setLoggingIn', false); // 登录失败
        ElNotification.error({
          title: 'Error',
          message: 'Login failed. Please check your credentials and try again.',
        });
        console.error('Login error:', error);
        throw error;
      });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('clearAuthData');
      router.push('/login');
      ElNotification.success({
        title: 'Success',
        message: 'Logout successful!',
      });
      resolve();
    }).catch(error => {
      ElNotification.error({
        title: 'Error',
        message: 'Logout failed. Please try again.',
      });
      console.error('Logout error:', error);
      throw error;
    });
  },
  refreshToken({ commit, state }) {
    if (state.isTokenRefreshing || state.isLoggingIn) { // 如果正在刷新或登录，则不再执行
      return Promise.resolve();
    }
    commit('setTokenRefreshing', true);
    return axios.post('/api/token/refresh/', {
      refresh: localStorage.getItem('refresh_token')
    })
      .then(response => {
        const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
        commit('setToken', response.data.access);
        commit('setTokenExpirationDate', expirationDate);
        commit('setTokenRefreshing', false);
        commit('setHasShownExpiryAlert', false);
        ElNotification.success({
          title: 'Success',
          message: 'Token refreshed successfully!',
        });
        return response;
      })
      .catch(error => {
        console.error('Refresh token error:', error);
        commit('clearAuthData');
        if (!state.hasShownExpiryAlert) {
          ElNotification.error({
            title: 'Error',
            message: 'Your session has expired. Please log in again.',
          });
          commit('setHasShownExpiryAlert', true);
        }
        throw error;
      });
  },
  checkAuthTimeout({ commit, dispatch, state }) {
    if (state.isLoggingIn) { // 如果正在登录，则跳过检查
      return;
    }
    const now = new Date();
    const expirationDate = new Date(state.tokenExpirationDate);
    const timeLeft = expirationDate.getTime() - now.getTime();
    
    if (timeLeft <= 0) {
      dispatch('logout');
      if (!state.hasShownExpiryAlert) {
        ElNotification.error({
          title: 'Error',
          message: 'Your session has expired. Please log in again.',
        });
        commit('setHasShownExpiryAlert', true);
      }
    } else if (timeLeft < 5 * 60 * 1000 && !state.isTokenRefreshing) { // 如果Token将在5分钟内过期，并且没有在刷新Token，则刷新Token
      dispatch('refreshToken');
    }
  }
};

const getters = {
  isAuthenticated(state) {
    return !!state.token;
  },
  uid(state) {
    return state.uid;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
