<template>
    <div class="banner-edit-container">
      <el-card>
        <h2>Carousel Details</h2>
        <el-form :model="banner" label-width="120px">
          <el-form-item label="Carousel Name" required>
            <el-input v-model="banner.name" placeholder="Please enter carousel name"></el-input>
          </el-form-item>
          <el-form-item label="Destination" required>
            <el-select v-model="banner.destination" placeholder="Please choose carousel destination">
              <el-option label="None" value="None"></el-option>
              <el-option label="Destination 1" value="destination1"></el-option>
              <el-option label="Destination 2" value="destination2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="URL Link">
            <el-input v-model="banner.url_link" placeholder="Please enter URL link"></el-input>
          </el-form-item>
          <el-form-item label="Order">
            <el-input-number v-model="banner.order" :min="1" placeholder="Please select the order of display for carousel"></el-input-number>
          </el-form-item>
          <el-form-item label="Status" required>
            <el-select v-model="banner.status" placeholder="Please select status">
              <el-option label="Active" value="active"></el-option>
              <el-option label="Inactive" value="inactive"></el-option>
            </el-select>
          </el-form-item>
          <h3>English (EN)</h3>
          <el-form-item label="Website Banner">
            <el-upload
              class="upload-demo"
              action="/api/upload"
              :show-file-list="false"
              :on-success="handleWebImageSuccess">
              <img v-if="banner.web_image" :src="banner.web_image" class="image-preview">
              <i v-else class="el-icon-plus"></i>
            </el-upload>
            <div>Allowed file types: jpg, jpeg, png</div>
            <div>1200 x 500</div>
          </el-form-item>
          <el-form-item label="Mobile Banner">
            <el-upload
              class="upload-demo"
              action="/api/upload"
              :show-file-list="false"
              :on-success="handleMobileImageSuccess">
              <img v-if="banner.mobile_image" :src="banner.mobile_image" class="image-preview">
              <i v-else class="el-icon-plus"></i>
            </el-upload>
            <div>Allowed file types: jpg, jpeg, png</div>
            <div>1200 x 500</div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveBanner">Save</el-button>
            <el-button @click="cancel">Cancel</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  
  export default {
    data() {
      return {
        banner: {
          name: '',
          destination: '',
          url_link: '',
          order: 1,
          status: 'active',
          web_image: '',
          mobile_image: ''
        },
        isNew: true
      }
    },
    methods: {
      async fetchBanner(id) {
        const response = await axios.get(`/api/banners/${id}/`)
        this.banner = response.data
        this.isNew = false
      },
      async saveBanner() {
        let formData = new FormData()
        for (let key in this.banner) {
          formData.append(key, this.banner[key])
        }
        if (this.isNew) {
          await axios.post('/api/banners/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
        } else {
          await axios.put(`/api/banners/${this.banner.id}/`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
        }
        this.$router.push({ name: 'BannerSetting' })
      },
      onFileChange(key, event) {
        this.banner[key] = event.target.files[0]
      },
      handleWebImageSuccess(response, file) {
        this.banner.web_image = URL.createObjectURL(file.raw)
      },
      handleMobileImageSuccess(response, file) {
        this.banner.mobile_image = URL.createObjectURL(file.raw)
      },
      cancel() {
        this.$router.push({ name: 'BannerSetting' })
      }
    },
    created() {
      const { id } = this.$route.params
      if (id !== 'new') {
        this.fetchBanner(id)
      }
    }
  }
  </script>
  
  <style scoped>
  .banner-edit-container {
    max-width: 800px;
    margin: 0 auto;
  }
  .upload-demo img {
    width: 1200px;
    height: 500px;
  }
  .image-preview {
    width: 300px;
    height: auto;
    margin-top: 10px;
  }
  </style>
  