<template>
    <div class="recharge-container">
      <el-card class="recharge-card">
        <h2>Recharge Chips</h2>
        <el-form @submit.prevent="recharge" label-position="top">
          <el-form-item label="Username" required>
            <el-input v-model="username" placeholder="Username" clearable>
              <template #prefix><el-icon><User /></el-icon></template>
            </el-input>
          </el-form-item>
          <el-form-item label="Amount" required>
            <el-input v-model="amount" placeholder="Amount" clearable>
              <template #prefix><el-icon><Money /></el-icon></template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="recharge">Recharge</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </template>
  
  <script>
  import axios from '../axios'
  
  export default {
    data() {
      return {
        username: '',
        amount: ''
      }
    },
    methods: {
      recharge() {
        if (!this.username || !this.amount) {
          this.$message.error('Username and amount are required')
          return
        }
        axios.post('/users/recharge/', {
          username: this.username,
          amount: this.amount
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(response => {
          this.$message.success(response.data.success)
          this.username = ''
          this.amount = ''
        })
        .catch(error => {
          if (error.response && error.response.data) {
            this.$message.error(`Recharge failed: ${error.response.data.error || 'Please try again.'}`)
          } else {
            this.$message.error('Recharge failed. Please try again.')
          }
        })
      }
    }
  }
  </script>
  
  