<template>
  <div class="promotion-edit-container">
    <el-card class="promotion-edit-card">
      <h2>{{ isEdit ? '编辑优惠' : '创建优惠' }}</h2>
      <el-form :model="promotion" label-width="120px">
        <el-form-item label="名称">
          <el-input v-model="promotion.name">
            <template #prepend>
              <el-icon><Edit /></el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="代码">
          <el-input v-model="promotion.code">
            <template #prepend>
              <el-icon><Document /></el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="promotion.description">
            <template #prepend>
              <el-icon><Edit /></el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="Claim Type">
          <el-select v-model="promotion.claim_type" placeholder="选择Claim Type">
            <el-option label="Percentage" value="percentage"></el-option>
            <el-option label="Exact" value="exact"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="promotion.claim_type === 'percentage'" label="奖金百分比">
          <el-input v-model.number="promotion.bonus_percentage" type="number">
            <template #append>%</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="promotion.claim_type === 'exact'" label="Bonus Amount">
          <el-input v-model.number="promotion.exact_amount" type="number">
            <template #prepend>
              <el-icon><Money /></el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="最高奖金">
          <el-input v-model.number="promotion.max_bonus" type="number">
            <template #prepend>
              <el-icon><Wallet /></el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker v-model="promotion.start_date" type="datetime" placeholder="选择日期和时间">
            <template #prepend>
              <el-icon><Calendar /></el-icon>
            </template>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker v-model="promotion.end_date" type="datetime" placeholder="选择日期和时间">
            <template #prepend>
              <el-icon><Calendar /></el-icon>
            </template>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否有效">
          <el-switch v-model="promotion.active" active-icon="el-icon-check" inactive-icon="el-icon-close"></el-switch>
        </el-form-item>
      </el-form>
      <span class="form-footer">
        <el-button size="default" type="danger" icon="CircleCloseFilled" @click="cancel">取消</el-button>
        <el-button size="default" type="success" @click="handleSubmitPromotion" icon="CircleCheckFilled">{{ isEdit ? '更新' : '创建' }}</el-button>
      </span>
    </el-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('promotions', ['promotion']),
    isEdit() {
      return this.promotion.id !== undefined;
    }
  },
  created() {
    const promotionId = this.$route.params.id;
    if (promotionId) {
      this.fetchPromotion(promotionId);
    } else {
      this.resetPromotion();
    }
  },
  methods: {
    ...mapActions('promotions', ['fetchPromotion', 'submitPromotion']),
    ...mapMutations('promotions', ['resetPromotion']),
    handleSubmitPromotion() {
      this.submitPromotion()
        .then(() => {
          this.$router.push('/promotions');
        })
        .catch(error => {
          console.error('Error saving promotion:', error);
        });
    },
    cancel() {
      this.$router.push('/promotions');
    }
  }
};
</script>
