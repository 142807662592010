<template>
  <div class="bonus-report">
    <h2>User Bonus Report</h2>
    <el-table :data="currentPageData" style="width: 100%" stripe border>
      <el-table-column
        label="No."
        width="50"
      >
        <template v-slot="scope">
          {{ (currentPage - 1) * pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="promotion" label="Promotion Name"></el-table-column>
      <el-table-column prop="amount" label="Amount"></el-table-column>
      <el-table-column prop="date" label="Date">
        <template v-slot="scope">
          {{ $filters.formatDate(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status">
        <template v-slot="scope">
          <el-tag :type="$getTagType(scope.row.status)">
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-info">
      Showing {{ (currentPage - 1) * pageSize + 1 }} to {{ Math.min(currentPage * pageSize, bonusReports.length) }} of {{ bonusReports.length }} entries
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="bonusReports.length"
      :page-size="pageSize"
      @current-change="handlePageChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    bonusReports: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10
    };
  },
  computed: {
    currentPageData() {
      const sortedReports = [...this.bonusReports].sort((a, b) => new Date(b.date) - new Date(a.date));
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return sortedReports.slice(start, end);
    }
  },
  methods: {
    handlePageChange(page) {
      this.currentPage = page;
    }
  }
};
</script>

<style scoped>
.bonus-report {
  padding: 20px;
}

.pagination-info {
  margin: 10px 0;
  text-align: right;
}
</style>
