<template>
  <div class="promotion-list-container">
    <el-card>
      <div class="header-container">
        <h2>红包优惠活动</h2>
        <el-button type="primary" size="default" @click="createRedPacket">创建红包优惠</el-button>
      </div>
      <el-table :data="redPackets" style="width: 100%" stripe border>
        <el-table-column type="index" label="No." width="50"></el-table-column>
        <el-table-column prop="name" label="活动名称"></el-table-column>
        <el-table-column prop="total_amount" label="总金额"></el-table-column>
        <el-table-column prop="min_amount" label="最小金额"></el-table-column>
        <el-table-column prop="max_amount" label="最大金额"></el-table-column>
        <el-table-column label="开始时间">
          <template v-slot="scope">
            {{ $filters.formatDate(scope.row.start_time) }}
          </template>
        </el-table-column>
        <el-table-column label="结束时间">
          <template v-slot="scope">
            {{ $filters.formatDate(scope.row.end_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="is_active" label="是否有效" width="100">
          <template v-slot="scope">
            <el-tag :type="scope.row.is_active ? 'success' : 'danger'">
              {{ scope.row.is_active ? '是' : '否' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="165">
          <template v-slot="scope">
            <el-button size="small" type="success" icon="Edit" @click="editRedPacket(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" icon="Delete" @click="confirmDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-divider></el-divider>
      <el-row :gutter="20">
        <el-col :span="20" class="button-container">
          <router-link to="/angpow">
            <el-button color="#626aef" icon="Wallet" plain>Test</el-button>
          </router-link>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog
      title="确认删除"
      v-model="dialogVisible"
      width="30%"
      @close="closeDialog">
      <span>您确定要删除这条优惠吗？</span>
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button size="small" type="danger" icon="CircleCloseFilled" @click="closeDialog">取消</el-button>
          <el-button size="small" type="success" icon="CircleCheckFilled" @click="deleteRedPacketHandler">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      dialogVisible: false,
      deleteId: null,
    };
  },
  computed: {
    ...mapState('redPacket', ['redPackets']),
  },
  created() {
    this.fetchRedPackets();
  },
  methods: {
    ...mapActions('redPacket', ['fetchRedPackets', 'deleteRedPacket']),
    editRedPacket(redPacket) {
      this.$router.push({ name: 'RedPacketSettings', params: { id: redPacket.id } });
    },
    confirmDelete(id) {
      this.deleteId = id;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.deleteId = null;
    },
    deleteRedPacketHandler() {
      this.deleteRedPacket(this.deleteId)
        .then(() => {
          this.$message.success('红包活动删除成功');
          this.closeDialog();
        })
        .catch(() => {
          this.$message.error('红包活动删除失败');
        });
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    createRedPacket() {
      this.$router.push({ name: 'RedPacketSettings' });
    }
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
