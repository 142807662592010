<template>
  <div class="change-password-container">
    <el-card class="change-password-card">
      <h2>Change Password</h2>
      <el-form label-position="top" :model="passwordForm">
        <el-form-item label="Current Password">
          <el-input type="password" v-model="passwordForm.current_password">
            <template #prefix>
              <el-icon class="input-icon"><Lock /></el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="New Password">
          <el-input type="password" v-model="passwordForm.new_password">
            <template #prefix>
              <el-icon class="input-icon"><Lock /></el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="Confirm New Password">
          <el-input type="password" v-model="passwordForm.confirm_new_password">
            <template #prefix>
              <el-icon class="input-icon"><Lock /></el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleChangePassword">Change Password</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ElMessageBox } from 'element-plus';

export default {
  data() {
    return {
      passwordForm: {
        current_password: '',
        new_password: '',
        confirm_new_password: ''
      }
    };
  },
  methods: {
    ...mapActions('user', ['changePassword']),
    ...mapActions('logout', ['logout']),
    handleChangePassword() {
      if (this.passwordForm.new_password !== this.passwordForm.confirm_new_password) {
        ElMessageBox.alert('New password fields do not match.', 'Error', { type: 'error' });
        return;
      }

      this.changePassword(this.passwordForm)
        .then(() => {
          ElMessageBox.alert('Password updated successfully', 'Success', {
            confirmButtonText: 'OK'
          }).then(() => {
            this.logout().then(() => {
              this.$router.push('/login');
            });
          });
        })
        .catch(error => {
          console.error('Failed to update password:', error);
          ElMessageBox.alert('Failed to update password', 'Error', { type: 'error' });
        });
    }
  }
};
</script>

