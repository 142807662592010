// src/filters.js

import moment from 'moment';

export function formatDate(value) {
  if (value) {
    return moment(String(value)).format('DD MMM YYYY hh:mm:ssA');
  }
  return value;
}

export function getTagType(status) {
  switch(status) {
    case 'Success':
    case 'Active':
      return 'success';
    case 'Inactive':
      return 'warning';
    case 'Failed':
    case 'Reject':
      return 'danger';
    default: 
      return 'info';
  }
}

