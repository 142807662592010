<template>
  <div class="banner-list-container">
    <el-card>
      <div class="header-container">
        <h2>Banner List</h2>
        <el-button type="primary" size="default" @click="createNew">New Record</el-button>
      </div>
      <el-table :data="banners" style="width: 100%" stripe border>
        <el-table-column type="index" label="No."></el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="destination" label="Destination"></el-table-column>
        <el-table-column prop="status" label="Status">
          <template v-slot:default="scope">
            <el-tag :type="scope.row.status ? 'success' : 'danger'">
              {{ scope.row.status ? 'Active' : 'Inactive' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="165">
          <template v-slot:default="scope">
            <div class="actions-container">
              <el-button size="small" type="success" icon="el-icon-edit" @click="editBanner(scope.row.id)">Edit</el-button>
              <el-button size="small" type="danger" icon="el-icon-delete" @click="confirmDeleteBanner(scope.row.id)">Delete</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="Confirm Deletion"
        v-model="dialogVisible"
        width="30%"
        @close="closeDialog">
        <span>Are you sure you want to delete this banner?</span>
        <template v-slot:footer>
          <span class="dialog-footer">
            <el-button size="small" type="danger" icon="el-icon-close" @click="closeDialog">Cancel</el-button>
            <el-button size="small" type="success" icon="el-icon-check" @click="deleteBannerWithNotification">Confirm</el-button>
          </span>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>
  
  <script>
  import axios from 'axios'
  
  export default {
    data() {
      return {
        banners: []
      }
    },
    methods: {
      async fetchBanners() {
        const response = await axios.get('/api/banners/')
        this.banners = response.data
      },
      createNew() {
        this.$router.push({ name: 'edit-banner', params: { id: 'new' } })
      },
      editBanner(id) {
        this.$router.push({ name: 'edit-banner', params: { id } })
      },
      async deleteBanner(id) {
        if (confirm('Are you sure you want to delete this banner?')) {
          await axios.delete(`/api/banners/${id}/`)
          this.fetchBanners()
        }
      }
    },
    created() {
      this.fetchBanners()
    }
  }
  </script>
  
  <style scoped>
  .banner-image {
    width: 100px;
    height: auto;
  }
  .btn {
    margin: 5px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  </style>
  