<template>
  <div class="register-container">
    <el-card class="register-card">
      <img src="@/assets/logo.png" alt="Logo" class="logo">
      <h2>Register</h2>
      <el-form @submit.prevent="submitRegister" label-position="top">
        <el-form-item label="Username" required>
          <el-input v-model="username" placeholder="Username" clearable>
            <template #prefix><el-icon><UserFilled /></el-icon></template>
          </el-input>
        </el-form-item>
        <el-form-item label="Full Name" required>
          <el-input v-model="fullName" placeholder="Full Name" clearable>
            <template #prefix><el-icon><User /></el-icon></template>
          </el-input>
        </el-form-item>
        <el-form-item label="Email Address" required>
          <el-input v-model="email" placeholder="Email Address" clearable>
            <template #prefix><el-icon><Message /></el-icon></template>
          </el-input>
        </el-form-item>
        <el-form-item label="Contact" required>
          <el-input v-model="phoneNoInput" placeholder="Mobile Number" clearable @input="updatePhoneNo">
            <template #prefix>
              <div class="phone-prefix">
                <img src="@/assets/malaysia-flag.png" alt="MY Flag" class="flag">
                <span class="prefix-text">+60</span>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="Password" required>
          <el-input v-model="password" type="password" placeholder="Password" clearable>
            <template #prefix><el-icon><Lock /></el-icon></template>
          </el-input>
        </el-form-item>
        <el-form-item label="Confirm Password" required>
          <el-input v-model="confirmPassword" type="password" placeholder="Confirm Password" clearable>
            <template #prefix><el-icon><Lock /></el-icon></template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitRegister">Register</el-button>
        </el-form-item>
      </el-form>
      <p v-if="referralCode">Referral Code: <strong>{{ referralCode }}</strong></p>
      <p>Already have an account? <el-button type="link" @click="goToLogin">Login here</el-button></p>
    </el-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ElMessage } from 'element-plus'

export default {
  data() {
    return {
      username: '',
      fullName: '',
      email: '',
      phoneNo: '',
      phoneNoInput: '',
      password: '',
      confirmPassword: ''
    }
  },
  computed: {
    ...mapState('register', ['referralCode', 'isReferralLocked', 'registrationStatus', 'registrationError'])
  },
  created() {
    this.initializeReferralCode()
  },
  methods: {
    ...mapActions('register', ['registerUser', 'initializeReferralCode']),
    updatePhoneNo(value) {
      this.phoneNo = '+60' + value;
    },
    submitRegister() {
      if (this.password !== this.confirmPassword) {
        ElMessage.error('Passwords do not match!')
        return
      }
      const userData = {
        username: this.username,
        full_name: this.fullName,
        email: this.email,
        phone_no: this.phoneNo,
        password: this.password,
        referral_by: this.referralCode || null
      }
      this.registerUser(userData).then(() => {
        if (this.registrationStatus === 'success') {
          ElMessage.success('Registration successful!')
          this.$router.push('/login')
        } else {
          ElMessage.error(`Registration failed: ${this.registrationError}`)
        }
      }).catch(error => {
        console.error(error)
      })
    },
    goToLogin() {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style scoped>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.register-card {
  width: 400px;
  padding: 20px;
  text-align: center;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

.phone-prefix {
  display: flex;
  align-items: center;
}

.flag {
  width: 20px;
  margin-right: 5px;
}

.prefix-text {
  color: #000;
  margin-right: 5px;
}
</style>
