// src/axios.js
import axios from 'axios';
import store from '@/store';
import router from '@/router';


const instance = axios.create({
  baseURL: 'https://api.easy88win.co',
  timeout: 10000,
})

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    const tokenExpirationDate = new Date(localStorage.getItem('token_expiration_date'));
    const now = new Date();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // 如果Token即将过期（例如5分钟内），刷新Token
    if (tokenExpirationDate.getTime() - now.getTime() < 5 * 60 * 1000 && !store.state.auth.isTokenRefreshing) {
      try {
        await store.dispatch('auth/refreshToken');
        config.headers['Authorization'] = `Bearer ${store.state.auth.token}`;
      } catch (error) {
        store.commit('auth/clearAuthData');
        if (!store.state.auth.hasShownExpiryAlert) {
          alert('Your session has expired. Please log in again.');
          store.commit('auth/setHasShownExpiryAlert', true);
        }
        router.push({ name: 'Login' });
        throw new Error('Session expired. Please log in again.');
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;