<template>
  <div class="login-container">
    <el-card class="login-card">
      <img src="@/assets/logo.png" alt="Logo" class="logo">
      <h2>Login</h2>
      <el-form @submit.prevent="loginUser" label-position="top">
        <el-form-item label="Username" required>
          <el-input v-model="username" placeholder="Username" clearable>
            <template #prefix><el-icon><User /></el-icon></template>
          </el-input>
        </el-form-item>
        <el-form-item label="Password" required>
          <el-input v-model="password" type="password" placeholder="Password" clearable>
            <template #prefix><el-icon><Lock /></el-icon></template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="default" color="#626aef" @click="loginUser">Login</el-button>
        </el-form-item>
      </el-form>
      <p>Don't have an account? <el-button size="small" @click="goToRegister">Register here</el-button></p>
      <p>Forget Password? <el-button size="small" @click="goToReset">Reset here</el-button></p>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    ...mapActions('auth', ['login']), 
    loginUser() {
      this.login({ username: this.username, password: this.password });
    },
    goToRegister() {
      this.$router.push({ name: 'Register' });
    },
    goToReset() {
      this.$router.push({ name: 'ResetPassword' });
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.login-card {
  width: 400px;
  padding: 20px;
  text-align: center;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}
</style>
