<template>
  <div class="dashboard-container">
    <el-card class="dashboard-card">
      <el-form @submit.prevent="confirmDistributeBonus" label-width="120px">
        <el-form-item label="Select">
          <el-select v-model="selectedPromotion" @change="handlePromotionChange" placeholder="Select promotion" style="width: 100%;">
            <el-option v-for="promotion in promotions" :key="promotion.id" :label="promotion.name" :value="promotion.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Search Users">
          <el-input v-model="searchQuery" placeholder="Enter user ID"></el-input>
          <el-button type="primary" size="small" @click="performSearch">Search</el-button>
        </el-form-item>
        <el-form-item label="Bonus Amount">
          <el-input v-model="bonusAmount" type="number" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <el-button color="#626aef" icon="Coin" @click="confirmDistributeBonus" plain>Distribute Bonus</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="searchResults" style="width: 100%; margin-top: 20px;" @selection-change="handleUserSelection">
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="uid" label="UID"></el-table-column>
        <el-table-column prop="full_name" label="Full Name"></el-table-column>
        <el-table-column prop="chips_balance" label="Chips Balance"></el-table-column>
      </el-table>
      <el-divider></el-divider>
      <admin-bonus-report 
        :bonusReports="bonusReports"
        @deactivate-bonus="handleDeactivateBonus"
      ></admin-bonus-report>
    </el-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ElMessageBox, ElNotification } from 'element-plus';
import AdminBonusReport from '@/components/Promotion/AdminBonusReport.vue';

export default {
  components: {
    AdminBonusReport
  },
  data() {
    return {
      searchQuery: '',
      bonusAmount: 0,
    };
  },
  computed: {
    ...mapState('promotions', ['promotions', 'searchResults', 'selectedPromotion', 'selectedUsers', 'bonusStatus']),
    ...mapState('bonusReport', ['bonusReports']),
  },
  methods: {
    ...mapActions('promotions', ['fetchPromotions', 'searchUsers', 'distributeBonus']),
    ...mapActions('bonusReport', ['fetchAdminBonusReports']),
    ...mapMutations('promotions', ['setSelectedPromotion', 'setSelectedUsers', 'setSearchResults', 'setBonusStatus']),
    handlePromotionChange(value) {
      this.setSelectedPromotion(value);
      this.updateBonusAmount();
    },
    updateBonusAmount() {
      const selectedPromotion = this.promotions.find(promo => promo.id === this.selectedPromotion);
      this.bonusAmount = selectedPromotion ? selectedPromotion.exact_amount || selectedPromotion.bonus_percentage : 0;
    },
    handleUserSelection(selectedUsers) {
      this.setSelectedUsers(selectedUsers.map(user => user.uid));
    },
    performSearch() {
      this.searchUsers(this.searchQuery);
    },
    confirmDistributeBonus() {
      ElMessageBox.confirm(
        'Are you sure you want to distribute the bonus?',
        'Confirm Bonus Distribution',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
        }
      ).then(() => {
        this.onDistributeBonus();
      }).catch(() => {
        ElNotification.info({
          title: 'Info',
          message: 'Bonus distribution cancelled',
        });
      });
    },
    async onDistributeBonus() {
      try {
        const result = await this.distributeBonus();
        if (result.failed_users.length > 0) {
          ElNotification.error({
            title: 'Error',
            message: `Failed to distribute bonus to users: ${result.failed_users.map(user => user[0]).join(', ')}`,
          });
        } else {
          ElNotification.success({
            title: 'Success',
            message: 'Bonus distributed successfully',
          });
        }
      } catch (error) {
        ElNotification.error({
          title: 'Error',
          message: 'Error distributing bonus',
        });
      } finally {
        // Ensure that data is refreshed and states are reset regardless of success or failure
        this.fetchAdminBonusReports();
        this.setSelectedUsers([]);
        this.setSearchResults([]);
        this.searchQuery = '';
        this.setSelectedPromotion(null);
        this.bonusAmount = 0;
      }
    },
    async handleDeactivateBonus(id) {
      try {
        await this.$store.dispatch('bonusReport/deactivateBonus', { id });
        this.fetchAdminBonusReports();
      } catch (error) {
        console.error('停用奖金错误:', error);
      }
    }
  },
  created() {
    this.fetchPromotions();
    this.fetchAdminBonusReports();
  },
};
</script>
