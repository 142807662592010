// store/modules/user.js
import axios from '@/axios';

const state = {
  user: JSON.parse(localStorage.getItem('user')) || {
    username: '',
    referral_code: '',
    referrals_count: 0,
    chips_balance: 0
  },
  viewedUser: null,
  users: [],
  searchResults: [],
  uid: localStorage.getItem('uid') || ''
};

const mutations = {
  setUid(state, uid) {
    state.uid = uid;
    localStorage.setItem('uid', uid);
  },
  setUser(state, user) {
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  setViewedUser(state, user) {
    state.viewedUser = user;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setSearchResults(state, results) {
    state.searchResults = results;
  }
};

const actions = {
  initializeUser({ commit }) {
    const uid = localStorage.getItem('uid');
    const user = JSON.parse(localStorage.getItem('user'));
    if (uid && user) {
      commit('setUid', uid);
      commit('setUser', user);
    }
  },
  fetchViewedUserProfile({ commit, state }, uid) {
    if (state.viewedUser && state.viewedUser.uid === uid) {
      return Promise.resolve(state.viewedUser);
    }
    return axios.get(`/users/profile/${uid}/`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        commit('setViewedUser', response.data);
        return response;
      })
      .catch(error => {
        console.error('获取用户详情错误:', error);
        throw error;
      });
  },
  fetchUserBalance({ commit, dispatch, state }) {
    return axios.get('/users/balance/', {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        commit('setUser', {
          ...state.user,
          chips_balance: response.data.chips_balance,
          referrals_count: response.data.referrals_count,
          referral_code: response.data.referral_code,
          username: response.data.username,
        });
        return response;
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          return dispatch('refreshToken', null, { root: true })
            .then(() => dispatch('fetchUserBalance'));
        } else {
          console.error('获取用户余额错误:', error);
          dispatch('logout', null, { root: true });
          throw error;
        }
      });
  },
  fetchUsers({ commit, state }) {
    if (state.users.length > 0) {
      return Promise.resolve(state.users);
    }
    return axios.get('/users/list/', {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        commit('setUsers', response.data);
        return response;
      })
      .catch(error => {
        console.error('获取用户列表错误:', error);
        throw error;
      });
  },
  searchUsers({ commit }, query) {
    return axios.get(`/users/search/?query=${query}`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        commit('setSearchResults', response.data);
        return response;
      })
      .catch(error => {
        console.error('搜索用户错误:', error);
        throw error;
      });
  },
  updateUserProfile({ commit, dispatch }, { uid, user }) {
    return axios.put(`/users/profile/${uid}/`, user, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        commit('setViewedUser', response.data);
        return response;
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          return dispatch('refreshToken', null, { root: true })
            .then(() => dispatch('updateUserProfile', { uid, user }));
        } else {
          console.error('更新用户资料错误:', error);
          throw error;
        }
      });
  },
  updateBankInfo({ state }, { uid, banks }) {
    return axios.put(`/users/${uid}/banks/`, banks, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        state.viewedUser.bank_accounts = response.data;
        return response;
      })
      .catch(error => {
        console.error('更新银行信息错误:', error);
        throw error;
      });
  },
  deleteBankInfo({ state }, { userId, bankId }) {
    return axios.delete(`/users/${userId}/banks/${bankId}/`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        state.viewedUser.bank_accounts = state.viewedUser.bank_accounts.filter(bank => bank.id !== bankId);
        return response;
      })
      .catch(error => {
        console.error('删除银行信息错误:', error);
        throw error;
      });
  }
};

const getters = {
  user(state) {
    return state.user;
  },
  viewedUser(state) {
    return state.viewedUser;
  },
  users(state) {
    return state.users;
  },
  userName(state) {
    return state.user.username;
  },
  searchResults(state) {
    return state.searchResults;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
