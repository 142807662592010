<template>
  <div class="dashboard-container">
    <el-card class="dashboard-card">
      <h2 class="dashboard-title">Dashboard</h2>
      <el-row :gutter="20">
        <el-col :span="12" class="user-info">
          <p><strong>Referral Code:</strong> {{ user.referral_code }}</p>
          <p><strong>Referred People:</strong> {{ user.referrals_count }}</p>
          <p><strong>Chips Balance:</strong> <span class="chips-balance">{{ user.chips_balance }}</span></p>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="20">
        <el-col :span="12" class="button-container">
          <router-link to="/recharge">
            <el-button color="#626aef" icon="Wallet" plain>Recharge</el-button>
          </router-link>
        </el-col>
        <el-col :span="12" class="button-container">
          <router-link to="/change-password">
            <el-button color="#626aef" icon="Key" plain>Change Password</el-button>
          </router-link>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <UserBonusReport :bonusReports="bonusReports" />
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserBonusReport from '@/components/Promotion/UserBonusReport.vue';

export default {
  components: {
    UserBonusReport
  },
  data() {
    return {
      bonusReports: []
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  created() {
    this.fetchUserBalance();
    this.loadBonusReports();
  },
  methods: {
    ...mapActions('user', ['fetchUserBalance']),
    ...mapActions('bonusReport', ['fetchUserBonusReports']),
    async loadBonusReports() {
      try {
        const response = await this.fetchUserBonusReports();
        this.bonusReports = response || [];
      } catch (error) {
        console.error('Failed to load bonus reports:', error);
      }
    },
    goToProfile() {
      const uid = localStorage.getItem('uid');
      this.$router.push({ name: 'Profile', query: { uid } });
    },
    handleSessionExpired() {
      this.logout().then(() => {
        this.$message.error('Session expired. Please log in again.');
      });
    }
  }
};
</script>

<style scoped>
.dashboard-container {
  padding: 20px;
}

.dashboard-card {
  width: 100%;
}

.dashboard-title {
  text-align: center;
  margin-bottom: 20px;
}

.user-info {
  text-align: left;
}

.chips-balance {
  color: green;
  font-weight: bold;
}

.button-container {
  text-align: center;
}
</style>
