import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Register from '@/views/auth/Register.vue';
import Login from '@/views/auth/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import AdminLayout from '@/components/Layouts/AdminLayout.vue';
import Dashboard from '@/views/Dashboard.vue';
import Profile from '@/views/users/UserProfile/index.vue';
import CreateUser from '@/views/users/CreateUser.vue';
import Recharge from '@/views/Recharge.vue';
import UserList from '@/views/users/UserList.vue';
import ChangePassword from '@/views/users/ChangePassword.vue';
import Promotions from '@/views/promotion/PromotionList.vue';
import PromotionEdit from '@/views/promotion/components/PromotionEdit.vue';
import PromotionGiven from '@/views/promotion/GivePromotion.vue';
import RedPacket from '@/views/promotion/redPacket/index.vue';
import RedPacketSettings from '@/views/promotion/redPacket/RedPacketSettings.vue';
import RedPacketPage from '@/views/RedPacketPage.vue';

import BannerSetting from '@/views/WebSettings/BannerSetting/index.vue';
import EditBanner from '@/views/WebSettings/BannerSetting/EditBanner.vue';

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/angpow',
    name: 'RedPacketPage',
    component: RedPacketPage
  },
  {
    path: '/',
    component: AdminLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'users/lists',
        name: 'UserList',
        component: UserList
      },
      {
        path: 'profile/UserEdit',
        name: 'Profile',
        component: Profile
      },
      {
        path: 'user/create',
        name: 'CreateUser',
        component: CreateUser
      },
      {
        path: 'recharge',
        name: 'Recharge',
        component: Recharge
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: ChangePassword
      },
      {
        path: 'promotions',
        name: 'Promotions',
        component: Promotions
      },
      {
        path: 'promotions/new',
        name: 'PromotionCreate',
        component: PromotionEdit
      },
      {
        path: 'promotions/:id/edit',
        name: 'PromotionEdit',
        component: PromotionEdit,
        props: true
      },
      {
        path: 'promotions/given',
        name: 'PromotionGiven',
        component: PromotionGiven
      },
      {
        path: 'setting/redpacket-settings',
        name: 'RedPacket',
        component: RedPacket
      },
      {
        path: '/red-packet-form/:id?',
        name: 'RedPacketSettings',
        component: RedPacketSettings,
        props: true
      },
      {
        path: 'banner-setting',
        name: 'BannerSetting',
        component: BannerSetting
      },
      {
        path: 'banner-setting/edit/',
        name: 'EditBanner',
        component: EditBanner,
        props: true
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 全局路由守卫
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      store.commit('auth/clearAuthData');
      if (!store.state.auth.hasShownExpiryAlert) {
        alert('Your session has expired. Please log in again.');
        store.commit('auth/setHasShownExpiryAlert', true);
      }
      next({ name: 'Login' });
    } else {
      try {
        await store.dispatch('auth/checkAuthTimeout');
        next();
      } catch {
        next({ name: 'Login' });
      }
    }
  } else {
    next();
  }
});

export default router;