// src/store/modules/BonusReport.js

import axios from '@/axios';
import { ElMessage } from 'element-plus';

const state = {
  bonusReports: [],
  bonusStatus: null,
};

const mutations = {
  setBonusReports(state, reports) {
    state.bonusReports = reports;
  },
  updateBonusStatus(state, { id, status }) {
    const report = state.bonusReports.find(report => report.id === id);
    if (report) {
      report.status = status;
    }
  },
  setBonusStatus(state, status) {
    state.bonusStatus = status;
  },
};

const actions = {
  async fetchUserBonusReports({ commit }) {
    try {
      const response = await axios.get('/api/user-bonus-reports/', {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      commit('setBonusReports', response.data || []);
      return response.data || [];
    } catch (error) {
      console.error('Fetch user bonus reports error:', error);
      throw error;
    }
  },
  fetchAdminBonusReports({ commit }) {
    return axios.get('/api/admin-bonus-reports/', {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        commit('setBonusReports', response.data || []);
      })
      .catch(error => {
        console.error('Fetch admin bonus reports error:', error);
        throw error;
      });
  },
  deactivateBonus({ commit, dispatch }, { id }) {
    return axios.post(`/api/admin-bonus-reports/${id}/deactivate/`, {}, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
    .then(() => {
      commit('updateBonusStatus', { id, status: 'Rejected' });
      ElMessage.success('奖金停用成功');
      dispatch('fetchAdminBonusReports');
    })
    .catch(error => {
      console.error('停用奖金错误:', error);
      ElMessage.error('停用奖金失败');
      throw error;
    });
  },
};

const getters = {
  bonusReports(state) {
    return state.bonusReports;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
