import axios from '@/axios';
import { ElNotification } from 'element-plus';

const state = {
  promotions: [],
  dialogVisible: false,
  promotionToDelete: null,
  promotion: {
    name: '',
    code: '',
    description: '',
    claim_type: '',
    bonus_percentage: 0,
    exact_amount: 0,
    max_bonus: 0,
    start_date: '',
    end_date: '',
    active: true
  },
  searchResults: [],
  selectedPromotion: null,
  selectedUsers: [],
  bonusStatus: null,
};

const mutations = {
  setPromotions(state, promotions) {
    state.promotions = promotions;
  },
  setDialogVisible(state, visible) {
    state.dialogVisible = visible;
  },
  setPromotionToDelete(state, promotionId) {
    state.promotionToDelete = promotionId;
  },
  setPromotion(state, promotion) {
    state.promotion = promotion;
  },
  resetPromotion(state) {
    state.promotion = {
      name: '',
      code: '',
      description: '',
      claim_type: '',
      bonus_percentage: 0,
      exact_amount: 0,
      max_bonus: 0,
      start_date: '',
      end_date: '',
      active: true
    };
  },
  setSearchResults(state, results) {
    state.searchResults = results;
  },
  setSelectedPromotion(state, promotion) {
    state.selectedPromotion = promotion;
  },
  setSelectedUsers(state, users) {
    state.selectedUsers = users;
  },
  setBonusStatus(state, status) {
    state.bonusStatus = status;
  }
};

const actions = {
  fetchPromotions({ commit }) {
    return axios.get('/api/promotions/')
      .then(response => {
        commit('setPromotions', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Fetch promotions error:', error);
        throw error;
      });
  },
  fetchPromotion({ commit }, id) {
    return axios.get(`/api/promotions/${id}/`)
      .then(response => {
        commit('setPromotion', response.data);
      })
      .catch(error => {
        console.error('Fetch promotion error:', error);
        throw error;
      });
  },
  submitPromotion({ dispatch, state }) {
    if (state.promotion.claim_type === 'percentage' && state.promotion.bonus_percentage > state.promotion.max_bonus) {
      ElNotification.error({
        title: '错误',
        message: '奖金百分比不能超过最高奖金'
      });
      return;
    }
    if (state.promotion.claim_type === 'exact' && state.promotion.exact_amount > state.promotion.max_bonus) {
      ElNotification.error({
        title: '错误',
        message: 'Exact Amount不能超过最高奖金'
      });
      return;
    }
    if (state.promotion.id) {
      return axios.put(`/api/promotions/${state.promotion.id}/`, state.promotion)
        .then(() => {
          dispatch('fetchPromotions');
          ElNotification.success({
            title: '成功',
            message: '优惠更新成功'
          });
        })
        .catch(error => {
          console.error('Update promotion error:', error);
        });
    } else {
      return axios.post('/api/promotions/', state.promotion)
        .then(() => {
          dispatch('fetchPromotions');
          ElNotification.success({
            title: '成功',
            message: '优惠创建成功'
          });
        })
        .catch(error => {
          console.error('Create promotion error:', error);
        });
    }
  },
  deletePromotion({ dispatch, commit, state }) {
    if (state.promotionToDelete) {
      return axios.delete(`/api/promotions/${state.promotionToDelete}/`)
        .then(() => {
          commit('setDialogVisible', false);
          commit('setPromotionToDelete', null);
          dispatch('fetchPromotions');
        })
        .catch(error => {
          commit('setDialogVisible', false);
          ElNotification.error({
            title: '错误',
            message: '删除优惠时出错'
          });
          console.error(`Failed to delete promotion: ${state.promotionToDelete}`, error);
          throw error;
        });
    }
  },
  searchUsers({ commit }, query) {
    return axios.get(`/users/search/?query=${query}`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        commit('setSearchResults', response.data);
      })
      .catch(error => {
        console.error('Search users error:', error);
        throw error;
      });
  },
  async distributeBonus({ commit, state }) {
    const selectedPromotion = state.promotions.find(promo => promo.id === state.selectedPromotion);
    const amount = selectedPromotion ? (selectedPromotion.exact_amount || selectedPromotion.bonus_percentage) : 0;

    try {
      const response = await axios.post('/api/distribute-bonus/', {
        users: state.selectedUsers,
        promotion: state.selectedPromotion,
        amount,
      }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });

      const { failed_users } = response.data;

      commit('setBonusStatus', failed_users.length > 0 ? 'Error distributing bonus' : 'Bonus distributed successfully');
      
      return response.data;
    } catch (error) {
      commit('setBonusStatus', 'Error distributing bonus');
      throw error;
    }
  }
};

const getters = {
  promotions(state) {
    return state.promotions;
  },
  dialogVisible(state) {
    return state.dialogVisible;
  },
  promotionToDelete(state) {
    return state.promotionToDelete;
  },
  promotion(state) {
    return state.promotion;
  },
  searchResults(state) {
    return state.searchResults;
  },
  selectedPromotion(state) {
    return state.selectedPromotion;
  },
  selectedUsers(state) {
    return state.selectedUsers;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
