<template>
  <div class="AdminLayout">
    <el-container class="main-layout">
      <LeftSidebar :is-mobile="isMobile" :is-sidebar-open="isSidebarOpen" :is-sidebar-collapsed="isSidebarCollapsed" @toggle-sidebar="toggleSidebar" />
      <el-container class="main-container" :class="{ 'main-container-mobile': isMobile, 'main-container-sidebar-open': isSidebarOpen && isMobile, 'main-container-collapsed': isSidebarCollapsed }" @click="handleContentClick">
        <TopNavBar :is-mobile="isMobile" :is-sidebar-collapsed="isSidebarCollapsed" @toggle-sidebar="toggleSidebar" />
        <el-main class="main-content">
          <router-view />
        </el-main>
        <el-footer class="footer">
          &copy; EZ Gaming All rights reserved. {{ new Date().getFullYear() }}
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import TopNavBar from '@/components/Layouts/components/TopNavBar.vue';
import LeftSidebar from '@/components/Layouts/components/LeftSidebar.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    TopNavBar,
    LeftSidebar,
  },
  data() {
    return {
      isMobile: false,
      isSidebarOpen: false,
      isSidebarCollapsed: false,
    };
  },
  created() {
    this.initializeUser();
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  methods: {
    ...mapActions('user', ['initializeUser']), // 使用 'user' 命名空间
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
      if (!this.isMobile) {
        this.isSidebarOpen = false;  // Ensure sidebar is closed when not in mobile view
      }
    },
    toggleSidebar() {
      if (this.isMobile) {
        this.isSidebarOpen = !this.isSidebarOpen;
      } else {
        this.isSidebarCollapsed = !this.isSidebarCollapsed;
      }
    },
    handleContentClick(event) {
      if (this.isMobile && this.isSidebarOpen && !event.target.closest('.sidebar') && !event.target.closest('.sidebar-toggle-btn')) {
        this.isSidebarOpen = false;
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  name: 'AdminLayout'
}
</script>

<style scoped>
.AdminLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-layout {
  flex: 1;
  display: flex;
  height: 100%;
}

.el-container {
  flex: 1;
  display: flex;
  height: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 250px);
  margin-left: 250px;
  transition: margin-left 0.3s, width 0.3s;
  padding-top: 65px; /* Ensure content is not hidden by the navbar */
  min-height: 100vh;
}

.main-container-mobile {
  width: 100%;
  margin-left: 0;
  position: relative;
}

.main-container-sidebar-open {
  position: fixed;
  width: 100vw;
}

.main-container-collapsed {
  width: calc(100% - 64px); /* Adjust width when sidebar is collapsed */
  margin-left: 64px;
}

.main-content {
  padding: 20px;
  background-color: #f5f5f5;
  flex: 1;
  overflow-y: auto;
}

.footer {
  height: auto;
  text-align: center;
  padding: 10px 0;
  background-color: #fff;
  color: #7e838d;
  margin-top: auto; /* Push footer to the bottom */
}
</style>
