<template>
    <div class="profile-container">
      <el-card class="profile-card">
        <h2>创建新用户</h2>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form label-position="top" :model="user">
              <el-form-item label="用户名">
                <el-input v-model="user.username">
                  <template #prefix>
                    <el-icon class="input-icon"><User /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="推荐人">
                <el-input v-model="user.referral_by_uid">
                  <template #prefix>
                    <el-icon class="input-icon"><User /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form label-position="top" :model="user">
              <el-form-item label="电话号码">
                <el-input v-model="user.phone_no">
                  <template #prefix>
                    <el-icon class="input-icon"><Phone /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="推荐链接">
                <el-input v-model="user.referral_link" disabled>
                  <template #prefix>
                    <el-icon class="input-icon"><Link /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
  
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form label-position="top" :model="user">
              <el-form-item label="姓名">
                <el-input v-model="user.full_name">
                  <template #prefix>
                    <el-icon class="input-icon"><User /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="邮箱">
                <el-input v-model="user.email">
                  <template #prefix>
                    <el-icon class="input-icon"><Message /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form label-position="top" :model="user">
              <el-form-item label="出生日期">
                <el-date-picker 
                  v-model="user.date_of_birth" 
                  type="date" 
                  placeholder="选择日期" 
                  format="YYYY-MM-DD" 
                  value-format="YYYY-MM-DD"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="状态">
                <el-select v-model="user.is_active">
                  <el-option label="活跃" :value="true"></el-option>
                  <el-option label="不活跃" :value="false"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
  
        <el-divider></el-divider>
  
        <h2>银行详情</h2>
        <el-table :data="banks" style="width: 100%" stripe border>
          <el-table-column label="银行分行">
            <template v-slot="scope">
              <el-select v-model="scope.row.bank_branch" placeholder="选择分行">
                <el-option
                  v-for="item in bankBranches"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="账户持有人">
            <template v-slot="scope">
              <el-input v-model="scope.row.bank_holder_name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="银行账户">
            <template v-slot="scope">
              <el-input v-model="scope.row.bank_account_number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template v-slot="scope">
              <el-input v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template v-slot="scope">
              <el-button type="danger" @click="removeBank(scope.$index)" icon="Delete" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="addBank" icon="CirclePlusFilled" style="margin-top: 10px;">添加银行</el-button>
  
        <el-divider></el-divider>
  
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-row :gutter="20" class="button-container">
                <el-col :span="12">
                  <el-button type="success" icon="CircleCheckFilled" @click="confirmSubmit" :loading="isSubmitting" :disabled="isSubmitting">提交</el-button>
                </el-col>
                <el-col :span="12">
                  <el-button type="danger" icon="CircleCloseFilled" @click="cancelForm">取消</el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  import { bankBranches } from '@/views/users/UserProfile/bank_branch.js';
  
  export default {
    data() {
      return {
        isSubmitting: false,
        bankBranches,
        banks: [],
        user: {
          username: '',
          referral_by_uid: '',
          phone_no: '',
          referral_link: '',
          full_name: '',
          email: '',
          date_of_birth: '',
          is_active: true,
          bank_accounts: []
        }
      };
    },
    methods: {
      ...mapActions('user', ['createUserProfile', 'updateBankInfo']),
      copyReferralLink() {
        const referralLink = this.user.referral_link || '--';
        navigator.clipboard.writeText(referralLink).then(() => {
          this.$message.success('推荐链接已复制到剪贴板');
        }).catch(() => {
          this.$message.error('复制推荐链接失败');
        });
      },
      confirmSubmit() {
        this.submitForm().then(success => {
          if (success) {
            this.$router.push('/user-list'); // 提交成功后跳转到用户列表页面
          }
        });
      },
      submitForm() {
        if (this.isSubmitting) return Promise.resolve(false);
        this.isSubmitting = true;
  
        const newBanks = this.banks.filter(bank => bank.bank_branch && bank.bank_holder_name && bank.bank_account_number);
        const bankAccounts = newBanks.map(bank => ({
          ...bank,
          user_uid: this.user.uid
        }));
  
        return this.createUserProfile({ user: this.user })
          .then(() => {
            if (newBanks.length > 0) {
              return this.updateBankInfo({ uid: this.user.uid, banks: bankAccounts });
            }
          })
          .then(() => {
            this.isSubmitting = false;
            this.$message.success('用户创建成功');
            return true;
          })
          .catch(error => {
            console.log(error);
            this.$message.error('用户创建失败');
            this.isSubmitting = false;
            return false;
          });
      },
      cancelForm() {
        this.$router.go(-1);
      },
      addBank() {
        this.banks.push({
          bank_branch: '',
          bank_holder_name: '',
          bank_account_number: '',
          remark: ''
        });
      },
      removeBank(index) {
        this.banks.splice(index, 1);
      }
    }
  };
  </script>
  
