<template>
  <div class="promotion-list-container">
    <el-card>
    <div class="header-container">
      <h2>优惠活动</h2>
      <el-button type="primary" size="default" @click="createPromotion">创建优惠</el-button>
    </div>
    <el-table :data="promotions" style="width: 100%" stripe border>
      <el-table-column type="index" label="No."></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="claim_type" label="类型">
        <template v-slot:default="scope">
          <el-tag class="custom-tag">{{ scope.row.claim_type === 'percentage' ? 'Percentage' : 'Exact' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="start_date" label="开始日期">
        <template v-slot="scope">
          {{ $filters.formatDate(scope.row.start_date) }}
        </template>
      </el-table-column>
      <el-table-column prop="end_date" label="结束日期">
        <template v-slot="scope">
          {{ $filters.formatDate(scope.row.end_date) }}
        </template>
      </el-table-column>
      <el-table-column prop="active" label="是否有效">
        <template v-slot:default="scope">
          <el-tag :type="scope.row.active ? 'success' : 'danger'">
            {{ scope.row.active ? '是' : '否' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="165">
        <template v-slot:default="scope">
          <div class="actions-container">
            <el-button size="small" type="success" icon="Edit" @click="editPromotion(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" icon="Delete" @click="confirmDeletePromotion(scope.row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="确认删除"
      v-model="dialogVisible"
      width="30%"
      @close="closeDialog">
      <span>您确定要删除这条优惠吗？</span>
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button size="small" type="danger" icon="CircleCloseFilled" @click="closeDialog">取消</el-button>
          <el-button size="small" type="success" icon="CircleCheckFilled" @click="deletePromotionWithNotification">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ElNotification } from 'element-plus';

export default {
  computed: {
    ...mapState('promotions', ['promotions', 'dialogVisible', 'promotionToDelete'])
  },
  created() {
    this.fetchPromotions();
  },
  methods: {
    ...mapActions('promotions', ['fetchPromotions', 'deletePromotion']),
    ...mapMutations('promotions', ['setDialogVisible', 'setPromotionToDelete']),
    createPromotion() {
      this.$router.push('/promotions/new');
    },
    editPromotion(promotion) {
      this.$router.push(`/promotions/${promotion.id}/edit`);
    },
    confirmDeletePromotion(promotionId) {
      this.setPromotionToDelete(promotionId);
      this.setDialogVisible(true);
    },
    closeDialog() {
      this.setDialogVisible(false);
      this.setPromotionToDelete(null);
    },
    async deletePromotionWithNotification() {
      try {
        await this.deletePromotion(this.promotionToDelete);
        this.closeDialog();
        this.fetchPromotions(); // Refresh the promotions list
        ElNotification.success({
          title: '成功',
          message: '优惠删除成功'
        });
      } catch (error) {
        ElNotification.error({
          title: '错误',
          message: '删除优惠时出错'
        });
      }
    }
  }
};
</script>
