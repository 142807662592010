<template>
  <div class="admin-bonus-report">
    <h2>Admin Bonus Report</h2>
    <el-table :data="currentPageData" style="width: 100%" stripe border>
      <el-table-column
        label="No."
        width="50"
      >
        <template v-slot="scope">
          {{ (currentPage - 1) * pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="promotion" label="Promotion Name"></el-table-column>
      <el-table-column prop="user_uid" label="User"></el-table-column>
      <el-table-column prop="amount" label="Amount"></el-table-column>
      <el-table-column prop="before_balance" label="Before Balance"></el-table-column>
      <el-table-column prop="after_balance" label="After Balance"></el-table-column>
      <el-table-column prop="processed_by" label="Processed By"></el-table-column>
      <el-table-column prop="date" label="Date">
        <template v-slot="scope">
          {{ $filters.formatDate(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status">
        <template v-slot="scope">
          <el-tag :type="$getTagType(scope.row.status)">
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Actions">
        <template v-slot="scope">
          <el-button 
            type="danger" 
            size="small" 
            @click="handleDeactivateBonus(scope.row.id)"
            :disabled="scope.row.status === 'Rejected'"
          >
            Deactivate
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-info">
      Showing {{ (currentPage - 1) * pageSize + 1 }} to {{ Math.min(currentPage * pageSize, bonusReports.length) }} of {{ bonusReports.length }} entries
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="bonusReports.length"
      :page-size="pageSize"
      @current-change="handlePageChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    bonusReports: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10
    };
  },
  computed: {
    currentPageData() {
      const sortedReports = [...this.bonusReports].sort((a, b) => new Date(b.date) - new Date(a.date));
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return sortedReports.slice(start, end);
    }
  },
  methods: {
    handlePageChange(page) {
      this.currentPage = page;
    },
    handleDeactivateBonus(id) {
      this.$emit('deactivate-bonus', id);
    }
  }
};
</script>

