<template>
  <div class="user-list-container">
    <el-card>
      <div class="header-container">
        <h2>用户列表</h2>
        <el-button type="primary" @click="createUser">创建新用户</el-button>
      </div>
      <el-table :data="currentPageData" style="width: 100%" stripe border>
        <el-table-column type="index" label="No." width="50"></el-table-column>
        <el-table-column prop="uid" label="用户ID" width="100"></el-table-column>
        <el-table-column label="姓名">
          <template v-slot="scope">
            {{ scope.row.full_name || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="推荐人">
          <template v-slot="scope">
            {{ scope.row.referral_by_uid || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="注册日期">
          <template v-slot="scope">
            {{ scope.row.date_joined ? $filters.formatDate(scope.row.date_joined) : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="上次登录日期">
          <template v-slot="scope">
            {{ scope.row.last_login ? $filters.formatDate(scope.row.last_login) : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="上次登录 IP">
          <template v-slot="scope">
            {{ scope.row.login_ip || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="chips_balance" label="筹码余额"></el-table-column>
        <el-table-column label="状态">
          <template v-slot="scope">
            <el-tag :type="$getTagType(scope.row.is_active ? 'Active' : 'Inactive')">
              {{ scope.row.is_active ? 'Active' : 'Inactive' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button @click="viewUser(scope.row.uid)" type="success" circle>
              <el-icon><View /></el-icon>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-info">
        显示 {{ (currentPage - 1) * pageSize + 1 }} 到 {{ Math.min(currentPage * pageSize, users.length) }} 共 {{ users.length }} 条目
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="users.length"
        :page-size="pageSize"
        @current-change="handlePageChange"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10
    };
  },
  computed: {
    ...mapGetters('user', ['users']),  // 确保命名空间正确
    currentPageData() {
      if (!this.users) {
        return [];
      }
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.users.slice(start, end);
    }
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions('user', ['fetchUsers', 'fetchViewedUserProfile']),  // 确保命名空间正确
    viewUser(uid) {
      this.fetchViewedUserProfile(uid).then(() => {
        this.$router.push({ path: '/profile/UserEdit', query: { uid } });
      }).catch(error => {
        console.error('获取用户详情失败', error);
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    createUser() {
      this.$router.push('/user/create');
    }
  }
}
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
