<template>
  <div class="profile-container">
    <el-card class="profile-card">
      <h2>用户详情</h2>
      <el-row v-if="viewedUser" :gutter="20">
        <el-col :span="12">
          <el-form label-position="top" :model="viewedUser">
            <el-form-item label="用户名">
              <el-input :value="viewedUser.username || '--'" disabled>
                <template #prefix>
                  <el-icon class="input-icon"><User /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="推荐人">
              <el-input :value="viewedUser.referral_by_uid || '--'" disabled>
                <template #prefix>
                  <el-icon class="input-icon"><User /></el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form label-position="top" :model="viewedUser">
            <el-form-item label="电话号码">
              <el-input :value="viewedUser.phone_no || '--'" disabled>
                <template #prefix>
                  <el-icon class="input-icon"><Phone /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="推荐链接">
              <el-input :value="viewedUser.referral_link || '--'">
                <template #prefix>
                  <el-icon class="input-icon"><Link /></el-icon>
                </template>
                <template #append>
                  <el-button @click="copyReferralLink" class="copy-button">
                    <el-icon><CopyDocument /></el-icon>
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <h2>编辑用户详情</h2>
      <el-row v-if="viewedUser" :gutter="20">
        <el-col :span="12">
          <el-form label-position="top" :model="viewedUser">
            <el-form-item label="姓名">
              <el-input v-model="viewedUser.full_name">
                <template #prefix>
                  <el-icon class="input-icon"><User /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="viewedUser.email">
                <template #prefix>
                  <el-icon class="input-icon"><Message /></el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form label-position="top" :model="viewedUser">
            <el-form-item label="出生日期">
              <el-date-picker 
                v-model="viewedUser.date_of_birth" 
                type="date" 
                placeholder="选择日期" 
                format="YYYY-MM-DD" 
                value-format="YYYY-MM-DD"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="viewedUser.is_active">
                <el-option label="活跃" :value="true"></el-option>
                <el-option label="不活跃" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <el-divider></el-divider>

      <h2>银行详情</h2>
      <el-table :data="banks" style="width: 100%" stripe border>
        <el-table-column label="银行分行">
          <template v-slot="scope">
            <el-select v-model="scope.row.bank_branch" placeholder="选择分行">
              <el-option
                v-for="item in bankBranches"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="账户持有人">
          <template v-slot="scope">
            <el-input v-model="scope.row.bank_holder_name"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="银行账户">
          <template v-slot="scope">
            <el-input v-model="scope.row.bank_account_number"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template v-slot="scope">
            <el-input v-model="scope.row.remark"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template v-slot="scope">
            <el-button type="danger" @click="removeBank(scope.$index, scope.row.id)" icon="Delete" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" @click="addBank" icon="CirclePlusFilled" style="margin-top: 10px;">添加银行</el-button>

      <el-divider></el-divider>

      <el-row>
        <el-col :span="24">
          <el-form-item>
            <el-row :gutter="20" class="button-container">
              <el-col :span="12">
                <el-button type="success" icon="CircleCheckFilled" @click="confirmSubmit" :loading="isSubmitting" :disabled="isSubmitting">提交</el-button>
              </el-col>
              <el-col :span="12">
                <el-button type="danger" icon="CircleCloseFilled" @click="cancelForm">取消</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <h2>附加详情</h2>
      <el-row v-if="viewedUser" :gutter="20">
        <el-col :span="12">
          <el-form label-position="top" :model="viewedUser">
            <el-form-item label="主筹码余额">
              <el-input :value="viewedUser.chips_balance || '--'" disabled>
                <template #prefix>
                  <el-icon class="input-icon"><Wallet /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="注册日期">
              <el-input :value="$filters.formatDate(viewedUser.date_joined) || '--'" disabled>
                <template #prefix>
                  <el-icon class="input-icon"><Calendar /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="上次登录日期">
              <el-input :value="$filters.formatDate(viewedUser.last_login) || '--'" disabled>
                <template #prefix>
                  <el-icon class="input-icon"><Calendar /></el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form label-position="top" :model="viewedUser">
            <el-form-item label="总奖金">
              <el-input :value="viewedUser.chips_balance || '--'" disabled>
                <template #prefix>
                  <el-icon class="input-icon"><Present /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="注册 IP">
              <el-input :value="viewedUser.login_ip || '--'" disabled>
                <template #prefix>
                  <el-icon class="input-icon"><Location /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="上次登录 IP">
              <el-input :value="viewedUser.login_ip || '--'" disabled>
                <template #prefix>
                  <el-icon class="input-icon"><Location /></el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { bankBranches } from '@/views/users/UserProfile/bank_branch.js';

export default {
  data() {
    return {
      isSubmitting: false,
      bankBranches,
      banks: [],
      originalBanks: []  // 保存原始银行信息
    };
  },
  computed: {
    ...mapGetters('user', ['viewedUser'])
  },
  watch: {
    viewedUser(newValue) {
      if (newValue && newValue.bank_accounts) {
        this.banks = newValue.bank_accounts;
        this.originalBanks = JSON.parse(JSON.stringify(newValue.bank_accounts));  // 保存原始银行信息
      }
    }
  },
  created() {
    const uid = this.$route.query.uid || localStorage.getItem('uid');
    if (!this.viewedUser || this.viewedUser.uid !== uid) {
      this.fetchViewedUserProfile(uid).then(() => {
        this.initBanks();
      });
    } else {
      this.initBanks();
    }
  },
  methods: {
    ...mapActions('user', ['fetchViewedUserProfile', 'updateUserProfile', 'updateBankInfo', 'deleteBankInfo']),
    copyReferralLink() {
      const referralLink = this.viewedUser.referral_link || '--';
      navigator.clipboard.writeText(referralLink).then(() => {
        this.$message.success('推荐链接已复制到剪贴板');
      }).catch(() => {
        this.$message.error('复制推荐链接失败');
      });
    },
    confirmSubmit() {
      this.submitForm().then(success => {
        if (success) {
          this.$router.go(0); // 使用 Vue Router 刷新页面
        }
      });
    },
    submitForm() {
      if (this.isSubmitting) return Promise.resolve(false);
      this.isSubmitting = true;

      const uid = this.$route.query.uid || localStorage.getItem('uid');
      const newBanks = this.banks.filter(bank => bank.bank_branch && bank.bank_holder_name && bank.bank_account_number);
      const bankAccounts = newBanks.map(bank => ({
        ...bank,
        user_uid: uid
      }));
      const requests = [this.updateUserProfile({ uid, user: this.viewedUser })];

      // 比较当前银行信息和原始银行信息是否有变化
      const banksChanged = JSON.stringify(newBanks) !== JSON.stringify(this.originalBanks);

      if (banksChanged) {
        requests.push(this.updateBankInfo({ uid, banks: bankAccounts }));
      }

      return Promise.all(requests)
        .then(() => {
          this.isSubmitting = false;
          this.$message.success('资料更新成功');
          this.$store.commit('user/setViewedUser', this.viewedUser);
          return true;
        })
        .catch(error => {
          console.log(error);
          this.$message.error('更新资料失败');
          this.isSubmitting = false;
          return false;
        });
    },
    cancelForm() {
      this.$router.go(-1);
    },
    addBank() {
      this.banks.push({
        bank_branch: '',
        bank_holder_name: '',
        bank_account_number: '',
        remark: ''
      });
    },
    removeBank(index, bankId) {
      const uid = this.$route.query.uid || localStorage.getItem('uid'); // 获取用户ID
      if (bankId) {
        this.deleteBankInfo({ userId: uid, bankId })
          .then(() => {
            this.banks.splice(index, 1);
            this.$message.success('删除银行信息成功');
            this.$router.go(0); // 删除银行信息后刷新页面
          })
          .catch(() => {
            this.$message.error('删除银行信息失败');
          });
      } else {
        this.banks.splice(index, 1);
      }
    },
    initBanks() {
      if (this.viewedUser && this.viewedUser.bank_accounts) {
        this.banks = this.viewedUser.bank_accounts;
        this.originalBanks = JSON.parse(JSON.stringify(this.viewedUser.bank_accounts));  // 保存原始银行信息
      }
      if (this.banks.length === 0) {
        this.addBank();
      }
    }
  }
};
</script>
